import React, { useEffect } from 'react';
import getConfig from 'next/config';
import { isEmpty } from 'lodash';

import Request from '@apester/requests';

import Router, { withRouter } from 'next/router';
import {
  isMobile, getWixInstanceId, getIsMFALogin,
} from '../utils/browser-utils';
import { sendForm } from '../utils/hubspot-utils';
import { useStateValue } from '../state/stateProvider';
import { getSessionId } from '../utils/event-helper';

const { publicRuntimeConfig: { config } } = getConfig();

const MainPage = ({ router }) => {
  const [{ user }] = useStateValue();

  const navigateToEditor = () => {
    window.location.href = config.EDITOR_URL;
  };

  const navigateToAcg = (instanceId) => {
    window.location.href = `${config.EDITOR_URL}/setup?isWixUser=true&instanceId=${instanceId}?sId=${getSessionId()}`;
  };

  const attachWixChannelToUser = async (instanceId) => {
    await Request.Patch(new URL(`${config.USERS_URL}/user/thirdParty/${instanceId}`),
      { userId: user.userId }, { withCredentials: true });
  };

  const onBoardingNotFinished = (userObj) => {
    const fieldsToCheck = ['company', 'companySize', 'jobTitle', 'vertical', 'needs'];
    return fieldsToCheck.some((field) => isEmpty(userObj[field]));
  };

  const navigateToOnboarding = ({
    company, companySize, jobTitle, vertical, needs,
  }) => {
    let page = '';
    if (isEmpty(company) || isEmpty(companySize) || isEmpty(jobTitle)) {
      page = 'company';
    } else if (isEmpty(vertical)) {
      page = 'vertical';
    } else if (isEmpty(needs)) {
      page = 'goals';
    }
    Router.push({ pathname: `/onboarding/${page}` });
  };

  useEffect(() => {
    if (!user) {
      return;
    }

    // in case of use in MFA, a call to "/user" won't get us the user. only the initial post message with creds will.
    // Social signups uses redirect so we have to send the user with the social callback as a qs.
    const isMFAlogin = getIsMFALogin();
    const wixInstanceId = getWixInstanceId();
    if (isMFAlogin) {
      Router.push({
        pathname: '/auth/mfa/mfa-login',
        query: router.query,
      });
      return;
    }

    if (user === 'error') {
      Router.push({
        pathname: '/auth/login',
        query: router.query,
      });
    } else if (wixInstanceId) {
      if (user.isNew || router.query.isNew) {
        sendForm(user, 'wix-trial');
      } else {
        attachWixChannelToUser(wixInstanceId);
      }
      navigateToAcg(wixInstanceId);
    } else if (user.isNew || router.query.isNew) {
      Router.push({
        pathname: '/auth/signup-success',
      });
    } else if (!isMobile()) {
      if (onBoardingNotFinished(user)) {
        navigateToOnboarding(user);
      } else {
        navigateToEditor();
      }
    } else {
      Router.push({
        pathname: '/discover',
      });
    }
  }, [user]);

  return <></>;
};

export default withRouter(MainPage);
